<template>
    <div class="left_menu">
        <el-menu default-active="/user" background-color='#263238' text-color="#ffffff" active-text-color="#ff5600"
            router>
            <el-menu-item class="menu-item" index="/home">
                <i class="el-icon-sunset"></i>
                <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item class="menu-item" index="/user">
                <i class="el-icon-user-solid"></i>
                <span slot="title">用户</span>
            </el-menu-item>
            <el-menu-item class="menu-item" index="/order/all">
                <i class="el-icon-user-solid"></i>
                <span slot="title">订单</span>
            </el-menu-item>
            <el-menu-item class="menu-item" index="/yuyue">
                <i class="el-icon-s-goods"></i>
                <span slot="title">挂号</span>
            </el-menu-item>
            <el-menu-item class="menu-item" index="/goods">
                <i class="el-icon-s-goods"></i>
                <span slot="title">商品</span>
            </el-menu-item>
            <el-menu-item class="menu-item" index="/goods/add">
                <i class="el-icon-s-goods"></i>
                <span slot="title">添加商品</span>
            </el-menu-item>
            <el-menu-item class="menu-item" index="/signout">
                <i class="el-icon-close"></i>
                <span slot="title">退出</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'LeftView',
    data() {
        return {
            openeds: ['1'],
        }
    },

    created() {
        // 从缓存获取user

    },

    mounted() {

    },
    methods: {
        signOut() {
            this.$confirm("", {
                title: "是否确定退出",
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                sessionStorage.removeItem("user")
                this.$router.push({
                    path: '/public/login',
                })
            })
        }
    },

}

</script>

<style scoped="scoped"  lang="less">
.left_menu {
    width: 100%;
    font-size: 16px;
    font-weight: 600;

    .menu-item {
        width: calc(10vw);
        height: 60px;
        line-height: 60px;
    }
}
</style>
